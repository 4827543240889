import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import * as style from '../assets/style/pages/eu-funds.module.scss';

const EuFundsPage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Fundusze EU', url: '/fundusze-eu' }
  ];

  return (
    <Layout>
      <Seo title="Fundusze EU">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.euFunds}>
        <div className="container">
          <div className={style.euFunds__content} dangerouslySetInnerHTML={{ __html: data.cockpitEuFunds.content.value }}></div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    cockpitEuFunds {
      content {
        value
      }
    }
  }
`;

export default EuFundsPage;
